const customer = [
  {
    text: '“Cylerian is an excellent solution for organizations that need log aggregation and security event detection. We recommend it to many of our clients that are early in the security maturity curve as well as to more mature clients that are for more than a SIEM solution.”👍',
    job: 'Security Risk & Compliance Practice Director',
    company: 'GreenPages'
  },
  {
    text: '“Having Cylerian provide us the ability to monitor and protect each endpoint of the network has qualitatively improved our security posture. Threat hunting is something we can do now. We can be ready to run our incident response playbook and if necessary use it as a source for forensic investigations. ”🌟',
    job: 'Head of Security',
    company: 'GALA'
  },
  {
    text: '“After reviewing many solutions and speaking with clients, we partnered with Cylerian because of the flexibility and completeness of their offering. With Cylerian as our backbone, we are able to provide organizations of all sizes advanced SIEM solutions that encompass security”⚡',
    name: 'Gokulnath C',
    job: 'CEO',
    company: 'Kryptos',
    avatar: '/img/quotes/Gokul_profile.png'
  }
];

export default customer;

