'use client';

import {useState} from 'react';

const HomeHeroAnim = () => {
  const [isAnim, setAnim] = useState(false);

  const startAnim = event => {
    event.target.classList.add('anim');
    setAnim(true);
  };

  const stopAnim = () => {
    setAnim(false);
  };

  const removeAnim = event => {
    if (!isAnim) {
      event.target.parentElement.classList.remove('anim');
    }
  };

  const itemsArr = Array.from({length: 24}, (_, i) => i + 1);
  const items = itemsArr.map(item => {
    return <div className={`item item${item}`} key={item}></div>;
  });

  return (
    <div className='home-hero-anim' onMouseOver={startAnim} onMouseLeave={stopAnim} onAnimationIteration={removeAnim}>
      <div className='lines'>
        <p className='value line1-value1'>
          <b>85%</b> Free memory
        </p>
        <p className='value line1-value2'>
          <b>15%</b> Free memory
        </p>
        <div className='line line1'></div>
        <p className='value line2-value1'>
          <b>15%</b> Host
        </p>
        <p className='value line2-value2'>
          <b>95%</b> Host
        </p>
        <div className='line line2'></div>
      </div>
      <div className='items'>{items}</div>
      <div className='chart'>
        <div className='chart1'></div>
        <div className='chart2'></div>
        <div className='chart3'></div>
        <div className='chart4'></div>
      </div>
      <div className='point'></div>
      <div className='union union1'>
        <b>24</b> Alerts
      </div>
      <div className='union union2'>
        <b>10</b> Alerts
      </div>
    </div>
  );
};

export default HomeHeroAnim;

