import Image from 'next/image';

const SwiperButtonNext = ({onClick}) => {
  return (
    <button className='nav-btn nav-btn-next' onClick={onClick}>
      <Image src='/svg/nav-arrow-right.svg' fill className='svg' alt='next'/>
    </button>
  );
};

export default SwiperButtonNext;

