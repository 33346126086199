import SwiperButtonNext from '../buttons/SwiperButtonNext/SwiperButtonNext';
import SwiperButtonPrev from '../buttons/SwiperButtonPrev/SwiperButtonPrev';

const SwiperNav = ({onPrevClick, onNextClick}) => {
  return (
    <div className='swiperNav'>
      <SwiperButtonPrev onClick={onPrevClick} />
      <SwiperButtonNext onClick={onNextClick} />
    </div>
  );
};

export default SwiperNav;

