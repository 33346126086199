import Image from 'next/image';

const SwiperButtonPrev = ({onClick}) => {
  return (
    <button className='nav-btn nav-btn-prev' onClick={onClick}>
      <Image src='/svg/nav-arrow-left.svg' fill className='svg' alt='previous'/>
    </button>
  );
};

export default SwiperButtonPrev;

