'use client';

import {motion} from 'framer-motion';
import useTopStyle from 'utils/useTopStyle';
import BookBtn from '../buttons/BookBtn/BookBtn';
import ArrowLink from '../buttons/ArrowLink/ArrowLink';
import HomeHeroAnim from './HomeHeroAnim/HomeHeroAnim';
import heroVariants from 'animation/heroVariants';
import spanVariants from 'animation/spanVariants';
import HomeHeroSvg from 'animation/svg-anim/HomeHeroSvg';

import {tagline} from 'data/common';

const HomeHero = () => {
  const ref = useTopStyle(72);

  return (
    <motion.section
      className='home-hero'
      ref={ref}
      initial={'hidden'}
      animate={'visible'}
      transition={{
        duration: 0.7,
        delay: 0.5,
        ease: 'easeInOut'
      }}
      variants={heroVariants}
    >
      <div className='container'>
        <div className='content'>
          <div className='bg-line'>
            <HomeHeroSvg />
          </div>
          <h2 className='title'>
            Security / Observability / Operations
            <span className='arrow-right'></span>
            <motion.span
              className='highlight'
              initial={'hidden'}
              animate={'visible'}
              transition={{
                duration: 0.8,
                delay: 0.8,
                ease: 'easeInOut'
              }}
              variants={spanVariants}
            >
              Together
            </motion.span>
          </h2>
          <p className='text'>{tagline}</p>
          <div className='buttons'>
            <BookBtn />
            <ArrowLink />
          </div>
        </div>
        <div className='design'>
          <HomeHeroAnim />
        </div>
      </div>
    </motion.section>
  );
};

export default HomeHero;
