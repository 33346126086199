'use client';

import {useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import Image from 'next/image';
import Link from 'next/link';
import useTopStyle from '../../utils/useTopStyle';
import integrations from '../../data/integrations';

const IntegrationsHoney = () => {
  const ref = useTopStyle();
  const screenWidth = useMediaQuery({query: '(max-width: 768px)'});
  const [logoArr, setLogoArr] = useState(integrations.slice(0, 10));

  useEffect(() => {
    const logoArr = !screenWidth ? integrations.slice(0, 10) : integrations.slice(0, 4);
    setLogoArr(logoArr);
  }, [screenWidth]);

  const images = logoArr.map((el, index) => {
    const {src, company} = el;
    return (
      <div key={index} className='logo'>
        <div key={index} className='image-container'>
          <Image src={src} className='image' alt='logo' fill/>
        </div>
        <p className='company'>{company}</p>
      </div>
    );
  });

  return (
    <section className='integrations-honey' id='integrations-honey' ref={ref}>
      <div className='container'>
        <h3 className='subtitle'>Integrations</h3>
        <div className='logos'>{images}</div>
        <Link href='/platform#integrations' legacyBehavior>
          <a className='arrow-link'>
            {'View more'}
            <span className='arrow-wrapper'>
              <span className='arrow'></span>
            </span>
          </a>
        </Link>
      </div>
    </section>
  );
};

export default IntegrationsHoney;

