const CustomerItem = ({data}) => {
  const {text, job, company} = data;

  return (
    <div className='customer-item'>
      <p className='text'>{text}</p>
      <div className='wrapper'>
        <div className='company'>
          <p className='job'>{job}</p>
          <p className='company'>{company}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerItem;

