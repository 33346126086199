import {useContext} from 'react';
import {ThemeContext} from '../../utils/ThemeContext';

const HomeHeroSvg = () => {
  const {theme} = useContext(ThemeContext);
  const linearGradientID = theme === 'dark' ? 'url(#paint0)' : 'url(#paint1)';

  const svgContainerStyle = {
    position: 'absolute',
    left: 'calc(50% - 147px)',
    top: 'calc(50% - 15px)',
    width: '246',
    height: '134'
  };
  const pathStyle = {
    strokeDasharray: '1200',
    strokeDashoffset: '1200',
    animation: 'draw 2s ease-in-out forwards',
    animationDelay: '1s',
    stroke: linearGradientID,
    strokeWidth: '2',
    strokeLinecap: 'round'
  };

  return (
    <div style={svgContainerStyle}>
      <svg width='246' height='134' viewBox='0 0 246 134' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M1 1C57.3077 78.2928 142.238 133 245 133' style={pathStyle} />
        <defs>
          <linearGradient id='paint0' x1='168.985' y1='148.559' x2='-36.5803' y2='19.8276' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#2463AC' />
            <stop offset='1' stopColor='#A3CDFF' stopOpacity='0' />
          </linearGradient>
          <linearGradient id='paint1' x1='405' y1='249.5' x2='-41.9314' y2='34.8624' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#FE7518' />
            <stop offset='1' stopColor='#FE7518' stopOpacity='0' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default HomeHeroSvg;

